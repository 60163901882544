import styles from "../App.module.scss";
import ScrollToTop from "react-scroll-to-top";
import { Link as Scroll } from 'react-scroll';

// フッターコンポーネントで使用するSVG
import FigLogo from "../assets/scss/svg/logo_fig.svg";
import ArrowIcon from "../assets/scss/svg/icon_all.svg";
import PrimeCastLogo from "../assets/scss/svg/logo_primecast.svg";

const Footer = () => {

    return (
        <footer className={styles["l-footer"]}>
            <ScrollToTop
                smooth
                className={styles["c-btn__pageTop"]}
                component={
                    <i className={styles["c-btn__pageTop--icon"]}>
                        <svg className={styles["c-iconSvg"] + " " + styles["c-icon__clr--white"]}>
                            <use xlinkHref={ArrowIcon + "#icon_arrow_T"} />
                        </svg>
                    </i>
                }
            />
            <div className={styles["l-footer__primecast"]}>
                <a href="//www.primecast.co.jp" target="_blank">
                    <img src={PrimeCastLogo} alt="" className={styles["l-footer__logo--primecast"]} />
                </a>
            </div>
            <div className={styles["l-footer__area"]}>
                <ul className={styles["c-list__footerNav"]}>
                    <li className={styles["c-list__footerNavItem"]}>
                        <Scroll to="anc_02" smooth={true} className="">
                            検針AIを試す
                        </Scroll>
                    </li>
                    <li className={styles["c-list__footerNavItem"]}>
                        <Scroll to="anc_03" smooth={true} className="">
                            3つの要素
                        </Scroll>
                    </li>
                    <li className={styles["c-list__footerNavItem"]}>
                        <Scroll to="anc_04" smooth={true} className="">
                            3つのこだわり
                        </Scroll>
                    </li>
                    <li className={styles["c-list__footerNavItem"]}>
                        <Scroll to="anc_05" smooth={true} className="">
                            お問い合わせ
                        </Scroll>
                    </li>
                    <li className={styles["c-list__footerNavItem"]}>
                        <a href="//www.primecast.co.jp" className="" target="_blank">
                            運営会社
                        </a>
                    </li>
                </ul>
                <a href="https://www.figinc.jp/" target="_blank">
                    <img src={FigLogo} alt="" className={styles["l-footer__logo--fig"]} />
                </a>
                <small className={styles["l-footer__copyright"]}>
                    FIG Group's PRIMECAST Co.,Ltd.
                </small>
            </div>
        </footer>
    );
}

export default Footer;