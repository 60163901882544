import AI from "./AI";
import axios from "axios";
import { BASE_URL } from "../config";
import styles from "../App.module.scss";
import { Checkbox, Form, Input, Modal } from "antd";
import ScrollAnimation from "react-animate-on-scroll";

// セクションコンポーネントで使用するIMAGE
import SecImagePc from "../assets/scss/img/sec01bg-pc.png";
import SecImageSp from "../assets/scss/img/sec01bg-sp.png";
import MainImagePc from "../assets/scss/img/mainimg01-pc.png";
import MainImageSp from "../assets/scss/img/mainimg01-sp.png";
import PointImage1 from "../assets/scss/img/pointimg_3-1.png";
import PointImage2 from "../assets/scss/img/pointimg_3-2.png";
import PointImage3 from "../assets/scss/img/pointimg_3-3.png";
import ProductImage from "../assets/scss/img/mainimg_product.png";
import PointImagePc1 from "../assets/scss/img/pointimg_3-1-pc.png";
import PointImagePc2 from "../assets/scss/img/pointimg_3-2-pc.png";
import PointImagePc3 from "../assets/scss/img/pointimg_3-3-pc.png";

// セクションコンポーネントで使用するSVG
import Logo from "../assets/scss/svg/logo.svg";
import SecImage from "../assets/scss/svg/sec01img.svg";
import PointIcon1 from "../assets/scss/svg/point3icon1-1.svg";
import PointIcon2 from "../assets/scss/svg/point3icon1-2.svg";
import PointIcon3 from "../assets/scss/svg/point3icon1-3.svg";
import PointIcon4 from "../assets/scss/svg/point3icon1-4.svg";
import PointIcon5 from "../assets/scss/svg/point3icon1-5.svg";
import PointIcon6 from "../assets/scss/svg/point3icon2-1.svg";
import PointIcon7 from "../assets/scss/svg/point3icon2-2.svg";
import PointIcon8 from "../assets/scss/svg/point3icon2-3.svg";
import PointIcon9 from "../assets/scss/svg/point3icon2-4.svg";
import PointIcon10 from "../assets/scss/svg/point3icon2-5.svg";
import PointIcon11 from "../assets/scss/svg/point3icon3-1.svg";
import PointIcon12 from "../assets/scss/svg/point3icon3-2.svg";
import PointIcon13 from "../assets/scss/svg/point3icon3-3.svg";
import PointIcon14 from "../assets/scss/svg/point3icon3-4.svg";
import PointIcon15 from "../assets/scss/svg/point3icon3-5.svg";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

const Section = () => {

    const [contactForm] = Form.useForm();

    // お問い合わせフォームの送信がクリックされた時の処理
    const contactSubmit = (contactValues: any) => {

        axios.post(BASE_URL, {
            "body": {
                "control": "email",
                "username": contactValues.username,
                "tel": contactValues.tel,
                "from_address": contactValues.from_address,
                "company": contactValues.company,
                "subject": contactValues.subject,
                "message": contactValues.message,
            }
        }).then(
            (response: any) => {
                if (response.data.data.success) {
                    Modal.success({
                        width: "800",
                        title:
                            <div className={styles.successSendEmailTitle}>
                                <CheckCircleOutlined className={styles.successSendEmailTitleIcon} />
                                送信完了
                            </div>,
                        content:
                            <div className={styles.successSendEmailContent}>
                                <div>この度はお問い合わせをいただき誠にありがとうございます。</div>
                                <div>お問い合わせ内容を確認させていただき、担当者からご連絡をさせていただきます。</div>
                                <div>恐れ入りますが、今しばらくお待ちくださいますよう、宜しくお願い申し上げます。</div>
                            </div>,
                        maskClosable: true,
                        zIndex: 100000,
                        getContainer: document.getElementById("root") ?? false,
                        centered: true,
                    });
                    contactForm.resetFields();
                } else {
                    Modal.error({
                        width: "800",
                        title:
                            <div className={styles.errorSendEmailTitle}>
                                <CloseCircleOutlined className={styles.errorSendEmailTitleIcon} />
                                送信エラー
                            </div>,
                        content:
                            <div className={styles.errorSendEmailContent}>
                                <div>お問い合わせの送信に失敗しました。</div>
                                <div>お手数ですが、再度お問い合わせの送信をしてください。</div>
                                <div>何度も失敗する場合は恐れ入りますが、お電話でお問い合わせください。</div>
                            </div>,
                        maskClosable: true,
                        zIndex: 100000,
                        getContainer: document.getElementById("root") ?? false,
                        centered: true,
                    });
                }
            }
        ).catch(
            (error: any) => {
                Modal.error({
                    width: "800",
                    title:
                        <div className={styles.errorSendEmailTitle}>
                            <CloseCircleOutlined className={styles.errorSendEmailTitleIcon} />
                            送信エラー
                        </div>,
                    content:
                        <div className={styles.errorSendEmailContent}>
                            <div>お問い合わせの送信に失敗しました。</div>
                            <div>お手数ですが、再度お問い合わせの送信をしてください。</div>
                            <div>何度も失敗する場合は恐れ入りますが、お電話でお問い合わせください。</div>
                        </div>,
                    maskClosable: true,
                    zIndex: 100000,
                    getContainer: document.getElementById("root") ?? false,
                    centered: true,
                });
            }
        );
    }

    return (
        <>
            {/* セクション1 ファーストビュー */}
            <section className={styles["l-section__firstView"]}>
                <span id="anc_00" className={styles["u-pos__anchor"]}></span>
                <h2 className={styles["l-section__firstView__catch"]}>
                    <span className={styles["l-section__firstView__catch--col01"]}>メーターの検針</span>
                    <span className={styles["l-section__firstView__catch--col02"]}>
                        AIで<span>自</span><span>動</span><span>化</span>
                    </span>
                    <span className={styles["l-section__firstView__catch--col03"]}>
                        日々の検針作業を楽にする<br />
                        独自のAI・IoTソリューション
                    </span>
                </h2>
                <img src={ProductImage} alt="" className={styles["l-section__firstView__productImg"]} />
                <img src={MainImagePc} alt="" className={styles["l-section__firstView__minvpc"]} />
                <img src={MainImageSp} alt="" className={styles["l-section__firstView__minvsp"]} />
            </section>

            {/* セクション2 メーター検針の悩み */}
            <ScrollAnimation animateIn="fadeIn">
                <section className={styles["l-section__contentArea"] + " " + styles["wow"] + " " + styles["fadeInUp"]}>
                    {/* <span id="anc_01" className={styles["u-pos__anchor"]}></span> */}
                    <h3 className={styles["l-section__contentArea__Tit__Aitest"] + " " + styles["u-mt20"]}>
                        メーター検針の悩み
                    </h3>
                    <ul className={styles["c-list__worries"]}>
                        <li className={styles["c-list__worriesItem"]}>
                            他の仕事もあるのに点検に時間を取られる
                        </li>
                        <li className={styles["c-list__worriesItem"]}>
                            点検漏れ・記載ミスをなくしたい
                        </li>
                        <li className={styles["c-list__worriesItem"]}>
                            同じ時間にメーターの記録をしたい
                        </li>
                        <li className={styles["c-list__worriesItem"]}>
                            自動で数値を入力してほしい
                        </li>
                        <li className={styles["c-list__worriesItem"]}>
                            数値をデータ化、グラフ化したい
                        </li>
                        <li className={styles["c-list__worriesItem"]}>
                            流行りの検針AIは信頼できるのか？
                        </li>
                        <li className={styles["c-list__worriesItem--man"]}>
                            <img src={SecImage} alt="" />
                        </li>
                    </ul>
                    <img src={SecImagePc} alt="" className={styles["l-section__contentArea"] + " " + styles["bgsec01--pc"]} />
                    <img src={SecImageSp} alt="" className={styles["l-section__contentArea"] + " " + styles["bgsec01--sp"]} />
                </section>
            </ScrollAnimation>

            {/* セクション3 検針AIを試す */}
            <AI />

            {/* セクション4 3つの要素 */}
            <section className={styles["l-section__contentArea"] + " " + styles["wow"] + " " + styles["fadeInUp"]}>
                <span id="anc_03" className={styles["u-pos__anchor"]}></span>
                <h3 className={styles["l-section__contentArea__Tit"]}>
                    <img src={Logo} alt="" className={styles["l-content__logoTit--sml"]} />
                    ３つの要素
                </h3>
                <ScrollAnimation animateIn="fadeIn">
                    <div className={styles["l-content__point01Box"]}>
                        <img src={PointImage1} alt="" className={styles["l-content__pointImg"]} />
                        <div className={styles["l-content__pointImg--pc"]}>
                            <img src={PointImagePc1} alt="" className={styles["l-content__pointImg--pc01"]} />
                        </div>
                        <ul className={styles["c-list__point"]}>
                            <li className={styles["c-list__pointName"]}>
                                <small>取り付け簡単</small>専用<br />カメラ
                            </li>
                            <li className={styles["c-list__pointItem"]}>
                                <p className={styles["c-list__pointNum"]}>01</p>
                                <p className={styles["c-list__pointIcon"]}><img src={PointIcon1} alt="" /></p>
                                <p className={styles["c-list__pointTxt"]}>
                                    <strong>独自開発</strong>の<br />
                                    <span className={styles["c-list__pointTxt--red"]}>IoT<br />
                                        カメラデバイス
                                    </span>
                                </p>
                            </li>
                            <li className={styles["c-list__pointItem"]}>
                                <p className={styles["c-list__pointNum"]}>02</p>
                                <p className={styles["c-list__pointIcon"]}><img src={PointIcon2} alt="" /></p>
                                <p className={styles["c-list__pointTxt"]}>
                                    カスタマイズが可能な<br />取り付けアームで<br />
                                    <span className={styles["c-list__pointTxt--red"]}>
                                        どこでもカンタン設置
                                    </span>
                                </p>
                            </li>
                            <li className={styles["c-list__pointItem"]}>
                                <p className={styles["c-list__pointNum"]}>03</p>
                                <p className={styles["c-list__pointIcon"]}><img src={PointIcon3} alt="" /></p>
                                <p className={styles["c-list__pointTxt"]}>
                                    {/* <strong>IP67</strong>に準拠した<br /> */}
                                    <span className={styles["c-list__pointTxt--red"]}>
                                        防水・防塵仕様
                                    </span>
                                    で<br />屋外、屋内問わず安定稼働
                                </p>
                            </li>
                            <li className={styles["c-list__pointItem"]}>
                                <p className={styles["c-list__pointNum"]}>04</p>
                                <p className={styles["c-list__pointIcon"]}><img src={PointIcon4} alt="" /></p>
                                <p className={styles["c-list__pointTxt"]}>
                                    単三電池×3本で<br />
                                    {/* <strong>500日間連続稼働</strong><br /> */}
                                    <strong>長時間連続稼働</strong><br />が可能な
                                    <span className={styles["c-list__pointTxt--red"]}>
                                        省エネ
                                    </span>
                                    設計
                                </p>
                            </li>
                            <li className={styles["c-list__pointItem"]}>
                                <p className={styles["c-list__pointNum"]}>05</p>
                                <p className={styles["c-list__pointIcon"]}><img src={PointIcon5} alt="" /></p>
                                <p className={styles["c-list__pointTxt"]}>
                                    設定した時間に撮影データ<br />をクラウドに自動送信する<br />
                                    <span className={styles["c-list__pointTxt--red"]}>
                                        タイマー機能
                                    </span>
                                    搭載
                                </p>
                            </li>
                        </ul>
                    </div>
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeIn">
                    <div className={styles["l-content__point02Box"]}>
                        <img src={PointImage2} alt="" className={styles["l-content__pointImg"]} />
                        <div className={styles["l-content__pointImg--pc"]}>
                            <img src={PointImagePc2} alt="" className={styles["l-content__pointImg--pc02"]} />
                        </div>
                        <ul className={styles["c-list__point"]}>
                            <li className={styles["c-list__pointName"]}>
                                <small>自動で読み取り</small>検針 AI
                            </li>
                            <li className={styles["c-list__pointItem"]}>
                                <p className={styles["c-list__pointNum"]}>01</p>
                                <p className={styles["c-list__pointIcon"]}><img src={PointIcon6} alt="" /></p>
                                <p className={styles["c-list__pointTxt"]}>
                                    独自に開発した<br />
                                    <span className={styles["c-list__pointTxt--red"]}>
                                        メーター読み取り<br />専用のAI
                                    </span>
                                </p>
                            </li>
                            <li className={styles["c-list__pointItem"]}>
                                <p className={styles["c-list__pointNum"]}>02</p>
                                <p className={styles["c-list__pointIcon"]}><img src={PointIcon7} alt="" /></p>
                                <p className={styles["c-list__pointTxt"]}>
                                    {/* 日々の自動アップデートに<br />より */}
                                    定期的なアップデートに<br />より精度が向上する<br />
                                    <span className={styles["c-list__pointTxt--red"]}>
                                        機械学習機能
                                    </span>
                                    を搭載
                                </p>
                            </li>
                            <li className={styles["c-list__pointItem"]}>
                                <p className={styles["c-list__pointNum"]}>03</p>
                                <p className={styles["c-list__pointIcon"]}><img src={PointIcon8} alt="" /></p>
                                <p className={styles["c-list__pointTxt"]}>
                                    <span className={styles["c-list__pointTxt--red"]}>
                                        様々な<small>（円形型・矩形型・ナナセグ型、カウンタ型）</small>
                                    </span>
                                    のメーターを読み取り可能
                                </p>
                            </li>
                            <li className={styles["c-list__pointItem"]}>
                                <p className={styles["c-list__pointNum"]}>04</p>
                                <p className={styles["c-list__pointIcon"]}><img src={PointIcon9} alt="" /></p>
                                <p className={styles["c-list__pointTxt"]}>
                                    <span className={styles["c-list__pointTxt--red"]}>
                                        AIに特化したサーバー
                                    </span>
                                    を使用しAIモデルの推論にかかる時間を最小限のコストで実現
                                </p>
                            </li>
                            <li className={styles["c-list__pointItem"]}>
                                <p className={styles["c-list__pointNum"]}>05</p>
                                <p className={styles["c-list__pointIcon"]}><img src={PointIcon10} alt="" /></p>
                                <p className={styles["c-list__pointTxt"]}>
                                    蓄積したデータは<br className={styles["br-pc"]} />
                                    <span className={styles["c-list__pointTxt--red"]}>
                                        クラウド環境で管理
                                    </span>
                                    専用のアプリケーションで表示が可能
                                </p>
                            </li>
                        </ul>
                    </div>
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeIn">
                    <div className={styles["l-content__point01Box"]}>
                        <img src={PointImage3} alt="" className={styles["l-content__pointImg"]} />
                        <div className={styles["l-content__pointImg--pc"]}>
                            <img src={PointImagePc3} alt="" className={styles["l-content__pointImg--pc03"]} />
                        </div>
                        <ul className={styles["c-list__point"]}>
                            <li className={styles["c-list__pointName"]}>
                                <small>データ見える化</small>管理<br /> アプリ
                            </li>
                            <li className={styles["c-list__pointItem"]}>
                                <p className={styles["c-list__pointNum"]}>01</p>
                                <p className={styles["c-list__pointIcon"]}><img src={PointIcon11} alt="" /></p>
                                <p className={styles["c-list__pointTxt"]}>
                                    <span className={styles["c-list__pointTxt--red"]}>
                                        簡単で<br />使いやすい
                                    </span>
                                    <br />アプリケーション
                                </p>
                            </li>
                            <li className={styles["c-list__pointItem"]}>
                                <p className={styles["c-list__pointNum"]}>02</p>
                                <p className={styles["c-list__pointIcon"]}><img src={PointIcon12} alt="" /></p>
                                <p className={styles["c-list__pointTxt"]}>
                                    カメラが撮影し<br />AIが解析したデータを<br />
                                    <span className={styles["c-list__pointTxt--red"]}>
                                        グラフなどで見える化
                                    </span>
                                </p>
                            </li>
                            <li className={styles["c-list__pointItem"]}>
                                <p className={styles["c-list__pointNum"]}>03</p>
                                <p className={styles["c-list__pointIcon"]}><img src={PointIcon13} alt="" /></p>
                                <p className={styles["c-list__pointTxt"]}>
                                    稼働中カメラの<br />ステータスを<br />
                                    <span className={styles["c-list__pointTxt--red"]}>
                                        監視・管理
                                    </span>
                                </p>
                            </li>
                            <li className={styles["c-list__pointItem"]}>
                                <p className={styles["c-list__pointNum"]}>04</p>
                                <p className={styles["c-list__pointIcon"]}><img src={PointIcon14} alt="" /></p>
                                <p className={styles["c-list__pointTxt"]}>
                                    APIによる<br />
                                    <span className={styles["c-list__pointTxt--red"]}>
                                        他システムとの連携
                                    </span>
                                </p>
                            </li>
                            <li className={styles["c-list__pointItem"]}>
                                <p className={styles["c-list__pointNum"]}>05</p>
                                <p className={styles["c-list__pointIcon"]}><img src={PointIcon15} alt="" /></p>
                                <p className={styles["c-list__pointTxt"]}>
                                    <span className={styles["c-list__pointTxt--red"]}>
                                        Webアプリはモチロン<small>Android・iOS・デスクトップアプリ</small>
                                    </span>
                                    での開発も可能
                                </p>
                            </li>
                        </ul>
                    </div>
                </ScrollAnimation>
            </section>

            {/* セクション5 3つのこだわり */}
            <ScrollAnimation animateIn="fadeIn">
                <section className={styles["l-section__contentArea"] + " " + styles["bgGray"] + " " + styles["wow"] + " " + styles["fadeInUp"]}>
                    <span id="anc_04" className={styles["u-pos__anchor"]}></span>
                    <h3 className={styles["l-section__contentArea__Tit"]}>３つのこだわり</h3>
                    <ul className={styles["c-list__obs3"]}>
                        <li className={styles["c-list__obs3Item"]}>
                            <p className={styles["c-list__obs3Item__Tit"]}>
                                柔軟な対応
                            </p>
                            <p className={styles["c-list__obs3Item__Txt"]}>
                                プライムメーターアイは、お客様のご要望に合わせてシステム全体を柔軟にカスタマイズできます。<br />
                                また、専用カメラ、検針AI、管理アプリはそれぞれ個別でのご利用も可能です。さらに、システムは試用していただく段階からスタッフを派遣し運用をサポート致します。
                            </p>
                        </li>
                        <li className={styles["c-list__obs3Item"]}>
                            <p className={styles["c-list__obs3Item__Tit"]}>
                                信頼性
                            </p>
                            <p className={styles["c-list__obs3Item__Txt"]}>
                                任意のIP制限によるファイヤーウォールの設定が可能で、堅牢なセキュリティを実現します。<br />
                                また、カメラは防水・防塵仕様。屋内外問わず、過酷な環境下での安定動作を保証致します。
                                {/* また、カメラはIP67に準拠した防水・防塵仕様。屋内外問わず、過酷な環境下での安定動作を保証致します。 */}
                            </p>
                        </li>
                        <li className={styles["c-list__obs3Item"]}>
                            <p className={styles["c-list__obs3Item__Tit"]}>
                                {/* 自動アップデート */}
                                定期アップデート
                            </p>
                            <p className={styles["c-list__obs3Item__Txt"]}>
                                {/* プライムメーターアイの検針AIは、毎日定時に学習データを取り込み、自動で精度の向上を行います。 */}
                                プライムメーターアイの検針AIは、学習データを取り込み、精度の向上を行います。
                            </p>
                        </li>
                    </ul>
                </section>
            </ScrollAnimation>

            {/* セクション6 お問い合わせ */}
            <ScrollAnimation animateIn="fadeIn">
                <section className={styles["l-section__contentArea"] + " " + styles["contact"]}>
                    <span id="anc_05" className={styles["u-pos__anchor"]}></span>
                    <h3 className={styles["l-content__logoTit--mid"]}><img src={Logo} alt="" /></h3>
                    <div className={styles["l-contentTxt"]}>
                        {/* <p>
                            わたしたちプライムキャストは、
                            <br className={styles["br-pc"]} />
                            最新のテクノロジーで産業の問題を解決し、
                            <br className={styles["br-pc"]} />
                            仕事の満足度を高めるシステム創りを手掛ける技術者集団です。
                        </p>
                        <p className={styles["u-mt20"]}>
                            プライムメーターアイのことはモチロン、
                            <br className={styles["br-pc"]} />
                            なにかお困りのことがございましたら、
                            <br className={styles["br-pc"]} />
                            IoT、AI、Webシステムの開発を得意とするエンジニアが
                            <br className={styles["br-pc"]} />
                            お客様のご相談にお応えいたします。
                        </p>
                        <p className={styles["u-mt20"]}></p>
                        <p className={styles["u-mt20"]}>
                            どうぞ、お気兼ねなくご連絡ください。
                        </p> */}
                        <p style={{ color: "red" }}>
                            ※現在、システムリニューアルを実施中のため、誠に恐れ入りますが
                            <br className={styles["br-pc"]} />
                            ご新規の契約、およびトライアルの受付を一時休止させていただいております。
                        </p>
                        <p style={{ marginTop: "20px" }}>
                            その他、ご質問などございましたら、下記フォームよりお問い合わせください。
                        </p>
                    </div>
                    <Form form={contactForm} onFinish={contactSubmit}>
                        <ul className={styles["c-list__contact"]}>
                            <Form.Item name="username">
                                <li className={styles["c-list__contactItem"]}>
                                    <p className={styles["c-list__contactName"] + " " + styles["must"]}>
                                        お名前
                                    </p>
                                    <Input maxLength={100} type="text" required className={styles["c-form__input"]} />
                                </li>
                            </Form.Item>
                            <Form.Item name="tel">
                                <li className={styles["c-list__contactItem"]}>
                                    <p className={styles["c-list__contactName"] + " " + styles["must"]}>
                                        電話番号
                                    </p>
                                    <Input maxLength={100} type="tel" required className={styles["c-form__input"]} />
                                </li>
                            </Form.Item>
                            <Form.Item name="from_address" >
                                <li className={styles["c-list__contactItem"]}>
                                    <p className={styles["c-list__contactName"] + " " + styles["must"]}>
                                        メールアドレス
                                    </p>
                                    <Input maxLength={100} type="email" required className={styles["c-form__input"]} />
                                </li>
                            </Form.Item>
                            <Form.Item name="company" >
                                <li className={styles["c-list__contactItem"]}>
                                    <p className={styles["c-list__contactName"]}>
                                        会社名
                                    </p>
                                    <Input maxLength={100} type="text" className={styles["c-form__input"]} />
                                </li>
                            </Form.Item>
                            <Form.Item name="subject" >
                                <li className={styles["c-list__contactItem"]}>
                                    <p className={styles["c-list__contactName"] + " " + styles["must"]}>
                                        件名
                                    </p>
                                    <Input maxLength={100} type="text" required className={styles["c-form__input"]} />
                                </li>
                            </Form.Item>
                            <Form.Item name="message" >
                                <li className={styles["c-list__contactItem"]}>
                                    <p className={styles["c-list__contactName"] + " " + styles["must"]}>
                                        お問い合わせ
                                    </p>
                                    <textarea name="" id="" required className={styles["c-form__area"]}></textarea>
                                </li>
                            </Form.Item>
                            {/* <Form.Item>
                                <Checkbox type="checkbox">
                                    <li className={styles["c-list__contactItem"]}>
                                        <span className={styles["c-form__checkLabel__Txt"] + " " + styles["must"]}>
                                            プライバシーポリシーに同意する
                                        </span>
                                    </li>
                                </Checkbox>
                            </Form.Item> */}
                            <Form.Item>
                                <li className={styles["c-list__contactItem"]}>
                                    <button className={styles["c-btn__send"]}>
                                        お問い合わせ内容を送信
                                    </button>
                                </li>
                            </Form.Item>
                        </ul>
                    </Form>
                </section>
            </ScrollAnimation>
        </>
    );
}

export default Section;