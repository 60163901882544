import { Drawer } from "antd";
import { useState } from "react";
import styles from "../App.module.scss";
import { Link as Scroll } from 'react-scroll';
import { useEffect } from "react";

// ヘッダーコンポーネントで使用するSVG
import Logo from "../assets/scss/svg/logo.svg";
import TelIcon from "../assets/scss/svg/icon_all.svg";

import FigLogo from "../assets/scss/img/FIG_logo_header_design_color.png";

const Header = () => {

    const [state, setState] = useState({
        drawer: false,                                       // モバイルスライドインメニューのOPEN/CLOSE
        headerFill: window.pageYOffset === 0 ? false : true, // ヘッダーメニューの白塗りつぶしのON/OFF
    });

    // モバイルスライドインメニューのOPEN/ClOSEをクリックした時に処理
    const isDrawer = () => {
        setState({ ...state, drawer: !state.drawer });
    }

    // ウィンドウサイズが768以下の場合はモバイルスライドインメニューを閉じる処理
    const windowReSize = () => {
        if (window.innerWidth > 768) {
            setState({ ...state, drawer: false });
        }
    }
    window.onresize = windowReSize;

    // スクロール位置が0の場合はヘッダーメニューの背景切り替える処理
    const windowScroll = () => {
        if (window.pageYOffset === 0 && state.headerFill === true) {
            setState({ ...state, headerFill: false });
        } else if (window.pageYOffset > 0 && state.headerFill === false) {
            setState({ ...state, headerFill: true });
        }
    }
    window.addEventListener("scroll", windowScroll);

    // モバイルスライドインメニューがOPEN/CLOSEでclassNameを変更する処理
    const drawerButtonClass = () => {
        if (state.drawer) {
            return styles["l-header__navi--spMenu"] + " " + styles["open"];
        } else {
            return styles["l-header__navi--spMenu"];
        }
    }

    // ヘッダーメニューの白塗りつぶしがON/OFFでclassNameを変更する処理
    const headerClass = () => {
        if (state.headerFill) {
            return styles["l-header"] + " " + styles["bg"] + " " + styles["l-header--bg"];
        } else {
            return styles["l-header"] + " " + styles["bg"] + " " + styles["first"];
        }
    }

    // プライムサイトからお問い合わせに遷移してきたときの処理
    const comePage = () => {
        const url = window.location.hash
        if (url === '#contact') document?.getElementById("contactBtn")?.click()
    }

    useEffect(() => {
        comePage()
    }, [])

    return (
        <>
            <div className={styles["fig"]}>
                <div>
                    <div>
                        <a href="https://www.figinc.jp/" target='_blank' rel="noreferrer">
                            <img
                                src={FigLogo}
                                alt="FIG株式会社"
                                width={192}
                                height={22.5}
                            />
                        </a>
                    </div>
                </div>
            </div>
            <header id="pagetop" className={headerClass()}>
                <h1>
                    <img
                        src={Logo}
                        className={styles["l-header__logo"]}
                        alt="PRIMEMETEREYE メーターの検針AIで自動化"
                    />
                </h1>
                <div className={styles["u-dsp__flex"]}>
                    <a href="tel:0362772912" className={styles["l-header__navi--spTel"]}>
                        <svg className={styles["c-iconSvg"] + " " + styles["c-icon__clr--base01"]}>
                            <use xlinkHref={TelIcon + "#icon_tel"} />
                        </svg>
                    </a>
                    <div id="spMenu" onClick={isDrawer} className={drawerButtonClass()}>
                        <span className={styles["l-header__navi--spMenuLine"]}></span>
                        <span className={styles["l-header__navi--spMenuLine"]}></span>
                        <span className={styles["l-header__navi--spMenuLine"]}></span>
                        <p className={styles["l-header__navi--spMenuTxt"]}></p>
                    </div>
                    <Drawer
                        visible={state.drawer}
                        placement="right"
                        getContainer={false}
                        maskStyle={{ "background": "rgba(8, 7, 57, 0.7)" }}
                    >
                        <ul className={styles["l-header__naviListSp"]}>
                            <li className={styles["l-header__naviListSpItem"]}>
                                <Scroll
                                    to="anc_00"
                                    smooth={true}
                                    className={styles["l-header__naviListSpItem--link"] + " " + styles["other"]}
                                    onClick={isDrawer}
                                >
                                    TOP
                                </Scroll>
                            </li>
                            <li className={styles["l-header__naviListSpItem"]}>
                                <Scroll
                                    to="anc_02"
                                    smooth={true}
                                    className={styles["l-header__naviListSpItem--link"] + " " + styles["other"]}
                                    onClick={isDrawer}
                                >
                                    検針AIを試す
                                </Scroll>
                            </li>
                            <li className={styles["l-header__naviListSpItem"]}>
                                <Scroll
                                    to="anc_03"
                                    smooth={true}
                                    className={styles["l-header__naviListSpItem--link"] + " " + styles["other"]}
                                    onClick={isDrawer}
                                >
                                    3つの要素
                                </Scroll>
                            </li>
                            <li className={styles["l-header__naviListSpItem"]}>
                                <Scroll
                                    to="anc_04"
                                    smooth={true}
                                    className={styles["l-header__naviListSpItem--link"] + " " + styles["other"]}
                                    onClick={isDrawer}
                                >
                                    3つのこだわり
                                </Scroll>
                            </li>
                            <li className={styles["l-header__naviListSpItem"]}>
                                <a
                                    href="//www.primecast.co.jp"
                                    className={styles["l-header__naviListSpItem--link"] + " " + styles["other"]}
                                    onClick={isDrawer}
                                    target="_blank"
                                >
                                    運営会社
                                </a>
                            </li>
                            <li className={styles["l-header__naviListSpItem"]}>
                                <Scroll
                                    to="anc_05"
                                    smooth={true}
                                    className={styles["l-header__naviListSpItem--link"] + " " + styles["other"]}
                                    onClick={isDrawer}
                                >
                                    お問い合わせ
                                </Scroll>
                            </li>
                            <li className={styles["l-header__naviListSpItem--bg"]}></li>
                        </ul>
                    </Drawer>
                </div>
                <ul className={styles["c-list__gnav"]}>
                    <li className={styles["c-list__gnavItem"]}>
                        <Scroll
                            to="anc_02"
                            smooth={true}
                            className={styles["c-list__gnavLink"]}
                        >
                            検針AIを試す
                        </Scroll>
                    </li>
                    <li className={styles["c-list__gnavItem"]}>
                        <Scroll
                            to="anc_03"
                            smooth={true}
                            className={styles["c-list__gnavLink"]}
                        >
                            3つの要素
                        </Scroll>
                    </li>
                    <li className={styles["c-list__gnavItem"]}>
                        <Scroll
                            to="anc_04"
                            smooth={true}
                            className={styles["c-list__gnavLink"]}
                        >
                            3つのこだわり
                        </Scroll>
                    </li>
                    <li className={styles["c-list__gnavItem"] + " " + styles["u-bg__white"]}>
                        <p className={styles["c-list__gnavBtn--tel"]}>
                            03-6277-2912<small>平日10:00~18:00</small>
                        </p>
                    </li>
                    <li className={styles["c-list__gnavItem"]}>
                        <Scroll
                            to="anc_05"
                            smooth={true}
                            className={styles["c-list__gnavBtn--contact"]}
                            id="contactBtn"
                        >
                            お問い合わせ
                        </Scroll>
                    </li>
                </ul>
            </header>
        </>
    );
}

export default Header;