import './App.css';
import 'antd/dist/antd.min.css';
import { Main } from './components/Main';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="*" element={<Main />} />
      </Routes>
    </Router>
  );
}

export default App;
