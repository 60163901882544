import axios from "axios";
import { BASE_URL } from "../config";
import DropZone from "react-dropzone";
import styles from "../App.module.scss";
import ReactLoading from "react-loading";
import { useEffect, useState } from "react";
import { Col, Row, Slider, Modal, Checkbox } from "antd";
import { CloseCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

// 認証で使用する画像
import Meter from "../assets/meter/meter.png";
import Needle from "../assets/meter/needle.png";

// AI実行時に使用する画像
import Maru from "../assets/meter/maru2.jpg";
import Segment from "../assets/meter/7seg.jpg";
import Counter from "../assets/meter/counter.jpg";
import MaruMulti from "../assets/meter/maru-multi.jpg";
import Segment2 from "../assets/meter/7seg-2.jpg";

// AI実行に使用する表示用の画像
import TestMeter01 from "../assets/scss/img/testimg06.jpg";
import TestMeter02 from "../assets/scss/img/testimg02.png";
import TestMeter03 from "../assets/scss/img/testimg03.png";
import TestMeter04 from "../assets/scss/img/testimg04.png";
import TestMeter05 from "../assets/scss/img/testimg05.png";

// AIコンポーネントで使用するIMAGE
import ProductImage from "../assets/scss/img/productimg01.png";

// AIコンポーネントで使用するSVG
import Logo from "../assets/scss/svg/logo_w.svg";
import SecImage from "../assets/scss/svg/sec02img.svg";
import UploadIcon from "../assets/scss/svg/icon_upload.svg";

const AI = () => {

    const [state, setState] = useState({
        imageBase64: "",                                            // メーターの画像データ
        captchaImageBase64: undefined,                              // メーター針の画像データ
        needleAngle: 0,                                             // 認証用のメーター針の角度
        smartSecurity: false,                                       // 認証モーダルの有無
        encryptId: localStorage.getItem("encrypt_id") ?? undefined, // 認証用のID
        token: localStorage.getItem("token") ?? undefined,          // 認証用のTOKEN
        loading: false,                                             // 認証用のローディングの有無
        aiLoading: false,                                           // AI処理中のローディングの有無
        aiResult: undefined,                                        // AI機能のレスポンスデータ
        aiResultNull: false,                                        // AI機能のレスポンスデータがNUllの場合の表示の有無
        controller: undefined,                                      // axiosをキャンセルするコントローラ
        signal: undefined,                                          // axiosをキャンセルするシグナル
        reloadButtonCount: 0,                                       // AI処理後の再実行までの10カウント
        loadingSize: window.innerWidth > 768 ? false : true,        // ウィンドウサイズによってローディングのサイズを変更
    });

    const aiResult: any = state.aiResult;     // AI機能のレスポンスデータ
    const controller: any = state.controller; // axiosをキャンセルするコントローラ

    // 初回にaxiosをキャンセルする為のコントローラとシグナルをステートにセットする
    useEffect(() => {
        const controller: any = new AbortController();
        const signal: any = controller.signal;
        setState({ ...state, controller: controller, signal: signal });
    }, []);

    // 認証モーダルを閉じる度にaxiosをキャンセルする為のコントローラとシグナルをステートにセットする
    useEffect(() => {
        if (!state.smartSecurity) {
            // 認証IDまたはTOKENが存在しない場合に新規コントローラ・シグナルをセットする
            if (!state.encryptId || !state.token) {
                const controller: any = new AbortController();
                const signal: any = controller.signal;
                setState({ ...state, controller: controller, signal: signal });
            }
        }
    }, [state.smartSecurity]);

    // imageBase64データが変更される度にaxiosをキャンセルする為のコントローラとシグナルをステートにセットする
    useEffect(() => {
        const controller: any = new AbortController();
        const signal: any = controller.signal;
        setState({ ...state, controller: controller, signal: signal });
    }, [state.imageBase64]);

    // AI処理後にボタンが使用できるようになるまでの10カウントを実行する
    useEffect(() => {
        if (state.reloadButtonCount) {
            const interval = setInterval(() => {
                setState({ ...state, reloadButtonCount: state.reloadButtonCount - 1 });
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [state.reloadButtonCount]);

    const closeSmartSecurity = () => {
        setState({
            ...state,
            smartSecurity: false,
            aiLoading: false,
        });
    }

    // ウィンドウサイズによってローディングのサイズを変更する処理
    const windowSize = () => {
        if (window.innerWidth > 768) {
            if (state.loadingSize) {
                setState({ ...state, loadingSize: false });
            }
        } else {
            if (!state.loadingSize) {
                setState({ ...state, loadingSize: true });
            }
        }
    }
    window.onresize = windowSize;

    // AI機能を使用するAPIを叩く
    const aiTest = (id: any, token: any) => {
        if (id && token) {
            // 認証ID・トークンが存在する場合は認証モーダルを閉じてAIレスポンスをリセットする
            setState({
                ...state,
                smartSecurity: false,
                encryptId: id,
                token: token,
                aiLoading: true,
                aiResult: undefined,
                aiResultNull: false,
            });
            // 認証ID・TOKENが存在する場合はAI機能を使用するAPIを叩く
            axios.post(BASE_URL, {
                "body": {
                    "control": "action",
                    "encrypt_id": id,
                    "token": token,
                    "image_base64": state.imageBase64,
                }
            }, {
                signal: state.signal,
            }).then((response) => {
                const res = response.data.data;
                if (res.ai_result) {
                    if (res.ai_result.data !== null) {
                        setState({
                            ...state,
                            smartSecurity: false,
                            aiResult: res.ai_result.data,
                            reloadButtonCount: 10,
                            encryptId: id,
                            token: token,
                        });
                    } else {
                        setState({
                            ...state,
                            smartSecurity: false,
                            aiResultNull: true,
                            reloadButtonCount: 10,
                            encryptId: id,
                            token: token,
                        });
                    }
                } else {
                    setState({
                        ...state,
                        smartSecurity: false,
                        aiResultNull: true,
                        reloadButtonCount: 10,
                        encryptId: id,
                        token: token,
                    });
                }
            }).catch((error) => {
                if (error.message !== "canceled") {
                    captchaModal(true);
                }
            });
        } else {
            // 認証ID・TOKENが存在しない場合は認証モーダルを表示する
            captchaModal(true);
        }
    }

    // 認証ID・画像を取得するAPIを叩く
    const captchaModal = (boolean: boolean) => {
        // 認証ID・TOKENをリセットする
        setState({
            ...state,
            smartSecurity: boolean,
            loading: boolean,
            encryptId: undefined,
            token: undefined,
        });
        // ローカルストレージの認証ID・TOKENを削除する
        localStorage.removeItem("encrypt_id");
        localStorage.removeItem("token");
        if (boolean) {
            // trueの場合は認証ID・画像を取得するAPIを叩く
            axios.post(BASE_URL, {
                "body": {
                    "control": "access",
                }
            }, {
                signal: state.signal,
            }).then((response) => {
                // レスポンスの認証ID・画像をセットする
                const res = response.data.data;
                setState({
                    ...state,
                    smartSecurity: boolean,
                    encryptId: res.encrypt_id,
                    captchaImageBase64: res.image,
                });
            });
        } else {
            // falseの場合は処理中のAPIをキャンセルする
            controller.abort();
        }
    }

    // 認証パスワードをチェックしてTOKENを取得するAPIを叩く
    const passwordCheck = (needleValue: number) => {
        // 認証で入力された数値を角度（0 ~ 250）に変換する
        const needleAngle = needleValue * 20.83333333333333;
        axios.post(BASE_URL, {
            "body": {
                "control": "check",
                "encrypt_id": state.encryptId,
                "password": needleAngle,
            }
        }, {
            signal: state.signal,
        }).then((response) => {
            const res = response.data.data;
            if (res.token) {
                // 認証に成功した場合はAI機能を使用し、ローカルストレージに認証ID・TOKENをセットする
                aiTest(res.encrypt_id, res.token);
                localStorage.setItem("encrypt_id", res.encrypt_id);
                localStorage.setItem("token", res.token);
            } else {
                // 認証に失敗した場合は再認証する
                Modal.error({
                    title:
                        <div className={styles.errorCertificationTitle}>
                            <CloseCircleOutlined className={styles.errorCertificationTitleIcon} />
                            認証失敗
                        </div>,
                    content:
                        <div className={styles.errorCertificationContent}>
                            <div> メーターの針を合わせる位置が間違っています。</div>
                            <div>お手数ですが再度お試しください。</div>
                        </div>,
                    zIndex: 100000,
                    getContainer: document.getElementById("root") ?? false,
                    centered: true,
                    maskClosable: true,
                });
                captchaModal(true);
            }
        });
    }

    // 選択された画像のimageBase64をリサイズする処理
    const resizeImageBase64 = async (imageBase64: string, minSize: number) => {
        var canvas = document.createElement('canvas');
        var ctx: any = canvas.getContext('2d');
        var image = new Image();
        image.src = imageBase64;
        image.crossOrigin = "Anonymous";
        return image.onload = () => {
            var dstWidth, dstHeight;
            if (image.width > image.height) {
                dstWidth = minSize;
                dstHeight = image.height * minSize / image.width;
            } else {
                dstHeight = minSize;
                dstWidth = image.width * minSize / image.height;
            }
            canvas.width = dstWidth;
            canvas.height = dstHeight;
            ctx.drawImage(image, 0, 0, image.width, image.height, 0, 0, dstWidth, dstHeight);
            const resizeImage: any = canvas.toDataURL();
            setState({
                ...state,
                imageBase64: resizeImage,
                aiResult: undefined,
                aiResultNull: false,
                aiLoading: false,
            });
        };
    }

    // キャプチャのメーター針の角度を変更する処理
    const changeNeedle = (needleValue: number) => {
        // 認証で入力された数値を角度（0 ~ 250）に変換する
        const needleAngle = needleValue * 20.83333333333333;
        setState({ ...state, needleAngle: needleAngle });
    };

    // ドラック＆ドロップされた画像をimageBase64に変換する処理
    const imageChangeDragDropZone = (image: any) => {
        const reader = new FileReader();
        reader.onload = (image: any) => {
            resizeImageBase64(image.target.result, 500);
        };
        reader.readAsDataURL(image);
    }

    const localMeterImageBoolean = (imageFileName: string) => {
        switch (imageFileName) {
            case "testimg06.4f9ab1508e87e02060fc.jpg": return "maru"
            case "testimg02.b5f6cade803a7441246a.png": return "maru-multi"
            case "testimg03.8c6fd2e28e691bfffbf9.png": return "segment"
            case "testimg04.6508b00cebbb96c7cde5.png": return "segment-2"
            case "testimg05.c428b049f4ba2fc16b45.png": return "counter"
            default: return ""
        }
    }

    // ドラック＆ドロップでファイルを読み込む時の処理
    const imageDragDrop = (acceptedFile: any, boolean: boolean) => {

        let searchMeterName = "";
        if (acceptedFile.length === 1 && acceptedFile[0].type.match(/image/)) {
            searchMeterName = acceptedFile[0].name;
        }

        if (boolean) {
            // 処理中のAPIをキャンセルする
            controller.abort();
            imageChangeDragDropZone(acceptedFile[0]);
        } else {
            if (acceptedFile.length === 1) {
                if (acceptedFile[0].type.match(/image/)) {
                    if (localMeterImageBoolean(searchMeterName)) {
                        controller.abort();
                        setSampleImage(localMeterImageBoolean(searchMeterName));
                    } else {
                        Modal.info({
                            width: "800",
                            centered: true,
                            maskClosable: true,
                            onCancel: closeSmartSecurity,
                            title:
                                <div className={styles.aiUseWarningTitle}>
                                    <ExclamationCircleOutlined className={styles.aiUseWarningTitleIcon} />
                                    検針AI使用について
                                </div>,
                            content:
                                <div className={styles.aiUseWarningContent}>
                                    <div>・個人情報および営業秘密に抵触するデータのアップロードはお控えください。</div>
                                    <div>・アップロード頂いた画像は、検針AIの学習用データとして活用させていただきます。</div>
                                    <div className={styles.aiUseWarningCheckBox}>
                                        <Checkbox onClick={() => { Modal.destroyAll(); imageDragDrop(acceptedFile, true) }}>
                                            <span className={styles["c-form__checkLabel__Txt"] + " " + styles["must"]}>
                                                上記の使用条件に同意する
                                            </span>
                                        </Checkbox>
                                    </div>
                                </div>,
                            zIndex: 100000,
                            getContainer: document.getElementById("root") ?? false,
                        });
                    }
                } else {
                    // その他の場合はアラートを表示する
                    Modal.error({
                        title:
                            <div className={styles.errorCertificationTitle}>
                                <CloseCircleOutlined className={styles.errorCertificationTitleIcon} />
                                システムエラー
                            </div>,
                        content:
                            <div className={styles.errorCertificationContent}>
                                <div>画像以外のデータは使用出来ません。</div>
                            </div>,
                        maskClosable: true,
                        zIndex: 10000,
                        getContainer: document.getElementById("root") ?? false,
                        centered: true,
                    });
                }
            } else {
                // その他の場合はアラートを表示する
                Modal.error({
                    title:
                        <div className={styles.errorCertificationTitle}>
                            <CloseCircleOutlined className={styles.errorCertificationTitleIcon} />
                            システムエラー
                        </div>,
                    content:
                        <div className={styles.errorCertificationContent}>
                            <div>アップロードできる画像は1枚のみです。</div>
                        </div>,
                    maskClosable: true,
                    zIndex: 10000,
                    getContainer: document.getElementById("root") ?? false,
                    centered: true,
                });
            }
        }
    }

    // 再選択された画像をimageBase64に変換する処理
    const imageChangeButton = (image: any) => {

        Modal.destroyAll();

        // 処理中のAPIをキャンセルする
        controller.abort();
        const files = image.target.files;
        const file = files[0];
        const reader = new FileReader();
        reader.onload = (image: any) => {
            resizeImageBase64(image.target.result, 500);
        };
        reader.readAsDataURL(file);
        image.target.value = null;
    }

    const setSampleImage = async (imageName: string) => {
        let meterImage = "";
        switch (imageName) {
            case "maru": meterImage = Maru;
                break;
            case "maru-multi": meterImage = MaruMulti;
                break;
            case "segment": meterImage = Segment;
                break;
            case "segment-2": meterImage = Segment2;
                break;
            case "counter": meterImage = Counter;
                break;
        }

        // 処理中のAPIをキャンセルする
        controller.abort();
        const img = document.createElement("img");
        img.src = meterImage;
        fetch(img.src)
            .then((res) => res.blob())
            .then((blob) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    if (reader && reader.result) {
                        const imageData: string = reader.result as string;
                        setState({
                            ...state,
                            imageBase64: imageData,
                            aiResult: undefined,
                            aiResultNull: false,
                            aiLoading: false,
                        });
                    }
                };
                reader.readAsDataURL(blob);
            });
    }

    const changeUseAiCheckBox = () => {
        const input: any = document.getElementById('fileUpload') as HTMLInputElement;
        input.type = 'file';
        input.accept = "image/*";
        input.onchange = imageChangeButton;
        input.click();
    }

    let smartSecurity: any;
    smartSecurity = (
        <Modal
            className={styles.smartSecurityModal}
            title="スマートセキュリティ"
            visible={state.smartSecurity}
            onCancel={() => { captchaModal(false) }}
            getContainer={document.getElementById("root") ?? false}
            footer={null}
            forceRender
            zIndex={50000}
            width={600}
            centered
        >
            {
                state.loading ?
                    <div className={styles.loadingArea}>
                        <div className={styles.loading}>
                            {
                                state.loadingSize ?
                                    <ReactLoading type="spokes" color="rgba(0, 58, 115, 0.839)" height="100px" width="100px" />
                                    :
                                    <ReactLoading type="spokes" color="rgba(0, 58, 115, 0.839)" height="200px" width="200px" />
                            }
                        </div>
                    </div>
                    :
                    <>
                        <div className={styles.captchaArea}>
                            <img className={styles.meterImage} src={Meter} />
                            <img className={styles.needleImage} style={{ transform: `rotateZ(${state.needleAngle}deg)` }} src={Needle} />
                            <img className={styles.captchaImage} src={`data:image/png;base64,${state.captchaImageBase64}`} />
                        </div>
                        <div className={styles.sliderArea}>
                            <Slider
                                min={0}
                                max={12}
                                step={0.1}
                                onChange={changeNeedle}
                                onAfterChange={passwordCheck}
                                trackStyle={{ padding: "8px 0" }}
                                handleStyle={{ top: "25%", height: "30px", width: "30px" }}
                            />
                        </div>
                        <div className={styles.disciplineText}>
                            メーター針が重なるように<br />
                            バーをスライドしてください
                        </div>
                        <div className={styles.expiryText}>
                            ※有効期限：5分
                        </div>
                    </>
            }
        </Modal>
    );

    return (
        <div className={styles.ai}>
            {smartSecurity}
            <section className={styles["l-section__contentArea"] + " " + styles["bgsec02"] + " " + styles["wow"] + " " + styles["fadeInUp"]}>
                <span id="anc_02" className={styles["u-pos__anchor"]}></span>
                <h3 className={styles["l-section__contentArea__Tit__Aitest"]}>
                    <img src={SecImage} alt="" />検針AIを試す
                </h3>
                <p className={styles["l-section__contentArea__Tit__Aitest--sub"]}>クラウド環境で稼働するプライムメーターアイの
                    <br className={styles["br-sp"]} />検針専用AIをお試ししませんか？</p>
                <div className={styles["l-content__Aitest"]} style={{ opacity: "95%" }}>
                    <p className={styles["l-content__Aitest__Txt"]}>
                        メーター画像を選択頂くか、ご自身のメーター画像アップロードして、
                        <span className={styles["l-content__Aitest__Txt--red"]}>AI判定ボタン</span>を押してください。</p>
                    <ul className={styles["c-list__Aitest"]}>
                        <li className={styles["c-list__AitestItem"]}>
                            <img
                                src={TestMeter01}
                                alt=""
                                style={{ cursor: (state.reloadButtonCount !== 0 || state.aiLoading === true) ? "not-allowed" : "pointer" }}
                                onClick={() => (state.reloadButtonCount === 0 && state.aiLoading === false) && setSampleImage("maru")}
                            />
                        </li>
                        <li className={styles["c-list__AitestItem"]}>
                            <img
                                src={TestMeter02}
                                alt=""
                                style={{ cursor: (state.reloadButtonCount !== 0 || state.aiLoading === true) ? "not-allowed" : "pointer" }}
                                onClick={() => (state.reloadButtonCount === 0 && state.aiLoading === false) && setSampleImage("maru-multi")}
                            />
                        </li>
                        <li className={styles["c-list__AitestItem"]}>
                            <img
                                src={TestMeter03}
                                alt=""
                                style={{ cursor: (state.reloadButtonCount !== 0 || state.aiLoading === true) ? "not-allowed" : "pointer" }}
                                onClick={() => (state.reloadButtonCount === 0 && state.aiLoading === false) && setSampleImage("segment")}
                            />
                        </li>
                        <li className={styles["c-list__AitestItem"]}>
                            <img
                                src={TestMeter04}
                                alt=""
                                style={{ cursor: (state.reloadButtonCount !== 0 || state.aiLoading === true) ? "not-allowed" : "pointer" }}
                                onClick={() => (state.reloadButtonCount === 0 && state.aiLoading === false) && setSampleImage("segment-2")}
                            />
                        </li>
                        <li className={styles["c-list__AitestItem"]}>
                            <img
                                src={TestMeter05}
                                alt=""
                                style={{ cursor: (state.reloadButtonCount !== 0 || state.aiLoading === true) ? "not-allowed" : "pointer" }}
                                onClick={() => (state.reloadButtonCount === 0 && state.aiLoading === false) && setSampleImage("counter")}
                            />
                        </li>
                        <label
                            className={styles["c-list__AitestItem"] + " " + styles["plus"]}
                            style={{ cursor: (state.reloadButtonCount !== 0 || state.aiLoading === true) ? "not-allowed" : "pointer" }}
                            onClick={() =>
                                (state.reloadButtonCount === 0 && state.aiLoading === false) &&
                                Modal.info({
                                    width: "800",
                                    centered: true,
                                    maskClosable: true,
                                    title:
                                        <div className={styles.aiUseWarningTitle}>
                                            <ExclamationCircleOutlined className={styles.aiUseWarningTitleIcon} />
                                            検針AI使用について
                                        </div>,
                                    content:
                                        <div className={styles.aiUseWarningContent}>
                                            <div>・個人情報および営業秘密に抵触するデータのアップロードはお控えください。</div>
                                            <div>・アップロード頂いた画像は、検針AIの学習用データとして活用させていただきます。</div>
                                            <div className={styles.aiUseWarningCheckBox}>
                                                <Checkbox onClick={changeUseAiCheckBox}>
                                                    <span className={styles["c-form__checkLabel__Txt"] + " " + styles["must"]}>
                                                        上記の使用条件に同意する
                                                    </span>
                                                </Checkbox>
                                                <input id="fileUpload" type="file" accept="image/*" style={{ display: "none" }} onChange={(e) => imageChangeButton(e)} />
                                            </div>
                                        </div>,
                                    zIndex: 100000,
                                    getContainer: document.getElementById("root") ?? false,
                                })
                            }
                        >
                            <i className={styles["plusadd"]}></i>
                            <p>メーター画像を<br />アップロード</p>
                        </label>
                        <input
                            id="fileUpload"
                            style={{ display: "none" }}
                            type="file"
                            accept="image/*"
                            onChange={(e) => imageChangeButton(e)}
                        />
                    </ul>
                    {
                        state.imageBase64 && state.imageBase64.length > 0 ?
                            <Row>
                                <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24} className={styles.imageDataArea}>
                                    {
                                        (state.reloadButtonCount === 0 && state.aiLoading === false) ?
                                            <DropZone multiple={false} onDrop={(e) => imageDragDrop(e, false)} noClick={true}>
                                                {({ getRootProps, getInputProps }) => (
                                                    <div
                                                        className={styles.imageDropZoneArea}
                                                        style={{
                                                            pointerEvents: "unset",
                                                            cursor: "pointer",
                                                            border: "2px #2A7FFF dotted",
                                                            background: "#E2F2FF",
                                                        }}
                                                        {...getRootProps({
                                                            onClick: () => Modal.info({
                                                                width: "800",
                                                                centered: true,
                                                                maskClosable: true,
                                                                onCancel: closeSmartSecurity,
                                                                title:
                                                                    <div className={styles.aiUseWarningTitle}>
                                                                        <ExclamationCircleOutlined className={styles.aiUseWarningTitleIcon} />
                                                                        検針AI使用について
                                                                    </div>,
                                                                content:
                                                                    <div className={styles.aiUseWarningContent}>
                                                                        <div>・個人情報および営業秘密に抵触するデータのアップロードはお控えください。</div>
                                                                        <div>・アップロード頂いた画像は、検針AIの学習用データとして活用させていただきます。</div>
                                                                        <div className={styles.aiUseWarningCheckBox}>
                                                                            <Checkbox onClick={changeUseAiCheckBox}>
                                                                                <span className={styles["c-form__checkLabel__Txt"] + " " + styles["must"]}>
                                                                                    上記の使用条件に同意する
                                                                                </span>
                                                                            </Checkbox>
                                                                            <input id="fileUpload" type="file" accept="image/*" style={{ display: "none" }} onChange={(e) => imageChangeButton(e)} />
                                                                        </div>
                                                                    </div>,
                                                                zIndex: 100000,
                                                                getContainer: document.getElementById("root") ?? false,
                                                            }),
                                                        })}
                                                    >
                                                        <input {...getInputProps()} />
                                                        <img className={styles.imageData} src={aiResult?.result_image ?? state.imageBase64} />
                                                    </div>
                                                )}
                                            </DropZone>
                                            :
                                            <div className={styles.imageDropZoneArea}>
                                                <img className={styles.imageData} src={aiResult?.result_image ?? state.imageBase64} />
                                            </div>
                                    }
                                </Col>
                                <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24} className={styles.aiResultArea}>
                                    {
                                        state.aiLoading &&
                                        <Row className={styles.aiLoadingArea}>
                                            <Col className={styles.aiLoading}>
                                                <ReactLoading type="bars" color="rgba(0, 58, 115, 0.839)" height="150px" width="150px" />
                                            </Col>
                                        </Row>
                                    }
                                    {
                                        state.aiResultNull &&
                                        <Row className={styles.aiResultNull}>
                                            <Col span={24}>
                                                <span className={styles.aiResultNullText}>
                                                    AIでの読み取りが出来ませんでした。
                                                </span>
                                            </Col>
                                            <Col span={24} className={styles.aiResultNullMeter}>
                                                <span className={styles.aiResultNullMeterTitle}>
                                                    【丸型 / 矩形型メーター】
                                                </span>
                                                <br />
                                                メーターの針の色が黒かつ、<br />
                                                背景色が白色のメーターのみ対応しております。
                                            </Col>
                                            <Col span={24} className={styles.aiResultNullMeter}>
                                                <span className={styles.aiResultNullMeterTitle}>
                                                    【カウンタ型メーター】
                                                </span>
                                                <br />
                                                数値色と背景色がくっきりと分かるものを送信してください。
                                            </Col>
                                            <Col span={24} className={styles.aiResultNullMeter}>
                                                <span className={styles.aiResultNullMeterTitle}>
                                                    【ナナセグ型メーター】
                                                </span>
                                                <br />
                                                数値が7桁未満のメーターかつ、<br />
                                                数値部分が明確な画像を送信してください。
                                            </Col>
                                            <Col span={24}>
                                                <span className={styles.aiResultNullUpdate}>
                                                    ※現時点では認識できないメーターでも、<br />
                                                    随時アップデートで対応予定です。
                                                </span>
                                            </Col>
                                        </Row>
                                    }
                                    {
                                        aiResult &&
                                        <Row className={styles.aiResultData}>
                                            <Col span={10} className={styles.aiResultDataTitleCol}>
                                                <div className={styles.aiResultDataTitle}>
                                                    カテゴリ
                                                </div>
                                            </Col>
                                            <Col span={14} className={styles.aiResultDataValueCol}>
                                                {aiResult?.category?.name ?? "読み取り不可"}
                                            </Col>
                                            <Col span={10} className={styles.aiResultDataTitleCol}>
                                                <div className={styles.aiResultDataTitle}>
                                                    説明
                                                </div>
                                            </Col>
                                            <Col span={14} className={styles.aiResultDataValueCol}>
                                                {aiResult?.category?.description ?? "読み取り不可"}
                                            </Col>
                                            <Col span={10} className={styles.aiResultDataTitleCol}>
                                                <div className={styles.aiResultDataTitle}>
                                                    メーター値
                                                </div>
                                            </Col>
                                            <Col span={14} className={styles.aiResultDataValueCol}>
                                                {aiResult?.value ?? "読み取り不可"}
                                            </Col>
                                            <Col span={10} className={styles.aiResultDataTitleCol}>
                                                <div className={styles.aiResultDataTitle}>
                                                    角度
                                                </div>
                                            </Col>
                                            <Col span={14} className={styles.aiResultDataValueCol}>
                                                {aiResult?.degree ?? "なし"}
                                            </Col>
                                            <Col span={10} className={styles.aiResultDataTitleCol}>
                                                <div className={styles.aiResultDataTitle}>
                                                    OCR
                                                </div>
                                            </Col>
                                            <Col span={14} className={styles.aiResultDataValueCol}>
                                                {aiResult?.ocr ?? "なし"}
                                            </Col>
                                        </Row>
                                    }
                                </Col>
                            </Row>
                            :
                            <DropZone multiple={false} onDrop={(e) => imageDragDrop(e, false)} noClick={true}>
                                {({ getRootProps, getInputProps }) => (
                                    <div
                                        className={styles["l-content__uploadArea"]}
                                        style={{ cursor: "pointer" }}
                                        {...getRootProps({
                                            onClick: () => Modal.info({
                                                width: "800",
                                                centered: true,
                                                maskClosable: true,
                                                onCancel: closeSmartSecurity,
                                                title:
                                                    <div className={styles.aiUseWarningTitle}>
                                                        <ExclamationCircleOutlined className={styles.aiUseWarningTitleIcon} />
                                                        検針AI使用について
                                                    </div>,
                                                content:
                                                    <div className={styles.aiUseWarningContent}>
                                                        <div>・個人情報および営業秘密に抵触するデータのアップロードはお控えください。</div>
                                                        <div>・アップロード頂いた画像は、検針AIの学習用データとして活用させていただきます。</div>
                                                        <div className={styles.aiUseWarningCheckBox}>
                                                            <Checkbox onClick={changeUseAiCheckBox}>
                                                                <span className={styles["c-form__checkLabel__Txt"] + " " + styles["must"]}>
                                                                    上記の使用条件に同意する
                                                                </span>
                                                            </Checkbox>
                                                            <input id="fileUpload" type="file" accept="image/*" style={{ display: "none" }} onChange={(e) => imageChangeButton(e)} />
                                                        </div>
                                                    </div>,
                                                zIndex: 100000,
                                                getContainer: document.getElementById("root") ?? false,
                                            }),
                                        })}
                                    >
                                        <input {...getInputProps()} />
                                        <i className={styles["l-content__uploadAreaIcon"]}>
                                            <img src={UploadIcon} alt="" />
                                        </i>
                                        <p className={styles[""]}>ここに画像をドラッグ＆ドロップ<br />してください。</p>
                                    </div>
                                )}
                            </DropZone>
                    }
                    {
                        !state.imageBase64 || aiResult || state.aiLoading || state.aiResultNull || state.reloadButtonCount ?
                            <button disabled style={{ background: "rgba(0,0,0,.25)", cursor: "not-allowed" }} className={styles["c-btn__Aitest"]}>
                                <img src={Logo} alt="" />
                                {
                                    state.reloadButtonCount !== 0 ?
                                        <span style={{ height: "20px", fontWeight: "bold" }}>{state.reloadButtonCount}秒後に再選択可能</span>
                                        :
                                        <span style={{ height: "20px", fontWeight: "bold" }}>でAI判定をしてみる</span>
                                }
                            </button>
                            :
                            <button onClick={() => aiTest(state.encryptId, state.token)} className={styles["c-btn__Aitest"]}>
                                <img src={Logo} alt="" />
                                <span style={{ height: "20px", fontWeight: "bold" }}>でAI判定をしてみる</span>
                            </button>
                    }
                    <img src={ProductImage} alt="" className={styles["l-content__Aitest__product"]} />
                </div>
            </section >
        </div >
    );
}

export default AI;